var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-2",
    attrs: {
      "id": "customer-list"
    }
  }, [_c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "show": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.customer')) + " ")]), _c('filter-customer', {
    attrs: {
      "updatedCustomerInfo": _vm.updatedCustomerInfo
    },
    on: {
      "filter-customer": _vm.handleFilteredCustomers,
      "filter-district": _vm.handleFilterDistrict,
      "toggle-add-customer": _vm.handleAddCustomer,
      "startDateFilter": _vm.filterStartDateBalance,
      "endDateFilter": _vm.filterEndDateBalance,
      "filter-city": _vm.filterIdCity
    }
  }), _c('b-table', {
    ref: "refCustomerListTable",
    staticStyle: {
      "max-height": "80vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.updatedCustomerInfo,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "busy": _vm.loading,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("customer.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "warning"
          }
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(code)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap text-uppercase fw-700"
        }, [_vm._v(" " + _vm._s(data.item.code) + " ")])];
      }
    }, {
      key: "cell(fullName)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          }
        }, [_c('div', {
          staticClass: "d-flex-between"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-2"
        }, [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": "",
            "toggle-class": "p-0",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('b-button', {
                staticClass: "btn-icon ",
                staticStyle: {
                  "background": "#D1ECF1 !important",
                  "border": "#D1ECF1"
                },
                attrs: {
                  "pill": ""
                }
              }, [_c('feather-icon', {
                staticStyle: {
                  "color": "#17A2B8 !important"
                },
                attrs: {
                  "icon": "MoreHorizontalIcon"
                }
              })], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.showImportModalDetailCustomer(data.item);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center gap-2"
        }, [_c('feather-icon', {
          staticStyle: {
            "color": "black !important",
            "font-weight": "600",
            "align-items": "center"
          },
          attrs: {
            "icon": "FileTextIcon",
            "size": "14"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('customer.details')) + " ")], 1)]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.showImportModalEditHandle(data.item);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center gap-2"
        }, [_c('feather-icon', {
          staticStyle: {
            "color": "black !important",
            "font-weight": "600",
            "align-items": "center"
          },
          attrs: {
            "icon": "Edit3Icon",
            "size": "14"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('customer.edit')) + " ")], 1)]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.handleAddContact(data.item);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center gap-2"
        }, [_c('feather-icon', {
          staticStyle: {
            "color": "black !important",
            "font-weight": "600",
            "align-items": "center"
          },
          attrs: {
            "icon": "UserPlusIcon",
            "size": "14"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('customer.addCustomerDetails')) + " ")], 1)])], 1), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(data.item.lastName) + " " + _vm._s(data.item.firstName) + " ")])], 1), !data.item.identifyNumber ? _c('div', {
          staticClass: "d-flex-center"
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-danger.window",
            value: _vm.$t('customer.noIdentifyNumber'),
            expression: "$t('customer.noIdentifyNumber')",
            modifiers: {
              "hover": true,
              "v-danger": true,
              "window": true
            }
          }],
          staticClass: "mr-25 text-danger",
          attrs: {
            "size": "14",
            "icon": "AlertCircleIcon"
          }
        })], 1) : _vm._e()])])];
      }
    }, {
      key: "cell(phoneNumber)",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', [_vm._v(_vm._s(data.item.phoneNumber || _vm.$t('customer.noPhoneNumber')))])])];
      }
    }, {
      key: "cell(emailAddress)",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "d-flex align-items-center"
        }, [_vm._v(" " + _vm._s(data.item.emailAddress) + " ")])];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(data) {
        var _data$item;
        return [_c('small', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "text-info"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.receivables)) + " ")])])];
      }
    }, {
      key: "cell(profit)",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "text-info"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.profit)) + " ")])])];
      }
    }, {
      key: "cell(show_details)",
      fn: function fn(row) {
        return [row.item.contacts && row.item.contacts.length > 0 ? _c('b-button', {
          staticClass: "rounded-circle p-1",
          staticStyle: {
            "background": "#D1ECF1 !important",
            "border": "#D1ECF1"
          },
          attrs: {
            "size": "sm-1",
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              return _vm.openButtonContact(row.item);
            }
          }
        }, [row.item._showDetails ? _c('div', [_c('feather-icon', {
          staticStyle: {
            "color": "#17A2B8 !important"
          },
          attrs: {
            "icon": "ChevronUpIcon"
          }
        })], 1) : _c('div', [_c('feather-icon', {
          staticStyle: {
            "color": "#17A2B8 !important"
          },
          attrs: {
            "icon": "ChevronDownIcon"
          }
        })], 1)]) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-table', {
          staticStyle: {
            "max-height": "100%"
          },
          attrs: {
            "sticky-header": true,
            "items": row.item.contacts,
            "responsive": "",
            "fields": _vm.tableColumnsContacts,
            "primary-key": "id",
            "show-empty": "",
            "thead-class": "d-none",
            "empty-text": _vm.$t('noMatchingResult'),
            "no-border-collapse": ""
          },
          scopedSlots: _vm._u([_vm._l(_vm.tableColumnsContacts, function (column) {
            return {
              key: "head(".concat(column.key, ")"),
              fn: function fn() {
                return [_c('span', {
                  key: column.label,
                  staticClass: "text-dark text-nowrap"
                })];
              },
              proxy: true
            };
          }), {
            key: "cell(code)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "text-nowrap text-uppercase fw-700"
              }, [_vm._v(" " + _vm._s(data.item.code) + " ")])];
            }
          }, {
            key: "cell(fullName)",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex justify-content-between pl-2"
              }, [_c('div', {
                staticClass: "d-flex gap-2 justify-content-start align-items-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "variant": "link",
                  "no-caret": "",
                  "toggle-class": "p-0",
                  "right": "",
                  "boundary": "window"
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('b-button', {
                      directives: [{
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: 'rgba(255, 255, 255, 0.15)',
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: {
                          "400": true
                        }
                      }],
                      staticClass: "btn-icon",
                      staticStyle: {
                        "background": "#D1ECF1 !important",
                        "border": "#D1ECF1"
                      },
                      attrs: {
                        "pill": ""
                      }
                    }, [_c('feather-icon', {
                      staticStyle: {
                        "color": "#17A2B8 !important"
                      },
                      attrs: {
                        "icon": "MoreHorizontalIcon"
                      }
                    })], 1)];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                on: {
                  "click": function click($event) {
                    return _vm.handleViewContact(data.item);
                  }
                }
              }, [_c('div', {
                staticClass: "d-flex align-items-center gap-2"
              }, [_c('feather-icon', {
                staticStyle: {
                  "color": "black !important",
                  "font-weight": "600",
                  "align-items": "center"
                },
                attrs: {
                  "icon": "FileTextIcon",
                  "size": "14"
                }
              }), _vm._v(" " + _vm._s(_vm.$t('customer.details')) + " ")], 1)]), _c('b-dropdown-item', {
                on: {
                  "click": function click($event) {
                    return _vm.handleEditContact(data.item);
                  }
                }
              }, [_c('div', {
                staticClass: "d-flex align-items-center gap-2"
              }, [_c('feather-icon', {
                staticStyle: {
                  "color": "black !important",
                  "font-weight": "600",
                  "align-items": "center"
                },
                attrs: {
                  "icon": "Edit3Icon",
                  "size": "14"
                }
              }), _vm._v(" " + _vm._s(_vm.$t('customer.edit')) + " ")], 1)]), _c('b-dropdown-item', {
                on: {
                  "click": function click($event) {
                    return _vm.showImportModalDeleteHandle(row.item.id, data.item.id);
                  }
                }
              }, [_c('div', {
                staticClass: "d-flex align-items-center gap-2"
              }, [_c('feather-icon', {
                staticStyle: {
                  "color": "black !important",
                  "font-weight": "600",
                  "align-items": "center"
                },
                attrs: {
                  "icon": "Trash2Icon",
                  "size": "14"
                }
              }), _vm._v(" " + _vm._s(_vm.$t('customer.delete')) + " ")], 1)])], 1), _c('h5', {
                staticClass: "mb-0"
              }, [_vm._v(" " + _vm._s(data.item.lastName) + " " + _vm._s(data.item.firstName) + " ")])], 1), !data.item.identifyNumber ? _c('div', {
                staticClass: "d-flex-center"
              }, [_c('feather-icon', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-danger.window",
                  value: _vm.$t('customer.noIdentifyNumber'),
                  expression: "$t('customer.noIdentifyNumber')",
                  modifiers: {
                    "hover": true,
                    "v-danger": true,
                    "window": true
                  }
                }],
                staticClass: "mr-25 text-danger",
                attrs: {
                  "size": "14",
                  "icon": "AlertCircleIcon"
                }
              })], 1) : _vm._e()])];
            }
          }, {
            key: "cell(phoneNumber)",
            fn: function fn(data) {
              return [_c('small', {
                staticClass: "d-flex align-items-center justify-content-start"
              }, [_c('span', [_vm._v(_vm._s(data.item.phoneNumber || ''))])])];
            }
          }, {
            key: "cell(emailAddress)",
            fn: function fn(data) {
              return [_c('small', {
                staticClass: "d-flex align-items-center"
              }, [!data ? _c('div', [_c('feather-icon', {
                staticClass: "mr-25",
                attrs: {
                  "size": "14",
                  "icon": "MailIcon"
                }
              })], 1) : _c('div', {
                staticClass: "d-none"
              }), _c('span', {}, [_vm._v(" " + _vm._s(data.item.emailAddress) + " ")])])];
            }
          }, {
            key: "cell(receivables)",
            fn: function fn() {
              return [_c('small', {
                staticClass: "d-flex align-items-center",
                staticStyle: {
                  "padding-left": "10%",
                  "width": "50%"
                }
              })];
            },
            proxy: true
          }, {
            key: "cell(profit)",
            fn: function fn() {
              return [_c('small', {
                staticClass: "d-flex align-items-center",
                staticStyle: {
                  "padding-left": "10%",
                  "width": "50%"
                }
              })];
            },
            proxy: true
          }], null, true)
        })];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: " justify-content-md-start",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "text-muted d-flex"
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.view')) + " ")]), _c('b-form-group', {
    staticClass: "mb-0 form-group-custom width-200"
  }, [_c('b-dropdown', {
    staticClass: "per-page-selector d-inline-block p-0 mx-25",
    attrs: {
      "variant": "warning",
      "text": "".concat(_vm.sizePerPage)
    },
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }, _vm._l(_vm.sizePerPageLgOptions, function (item) {
    return _c('b-dropdown-item', {
      key: item,
      on: {
        "click": function click($event) {
          _vm.sizePerPage = item;
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1)], 1), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.Customer')) + " " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')))])]), _c('b-col', {
    staticClass: "d-flex-center justify-content-md-end mt-1 mt-md-0 pr-lg-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-pagination', {
    staticClass: "pagination-warning mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalCustomers,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _vm.showImportModal ? _c('modal-edit-customer', {
    attrs: {
      "mode": _vm.checkModalCustomer === false ? 'edit' : 'add',
      "show-import-modal": _vm.showImportModal,
      "editCustomerModal": _vm.checkModalCustomer === false ? _vm.editCustomerModal : {},
      "readonlyModalDetail": _vm.readonly
    },
    on: {
      "update:showImportModal": function updateShowImportModal($event) {
        _vm.showImportModal = $event;
      },
      "update:show-import-modal": function updateShowImportModal($event) {
        _vm.showImportModal = $event;
      },
      "close-modal": _vm.handleCloseModal,
      "refetch-data": _vm.handleEditData,
      "back": _vm.onBack,
      "ok-detail": _vm.handleOkDetailCustomer
    }
  }) : _vm._e(), _vm.showImportModalDetail ? _c('ModalEditContact', {
    attrs: {
      "mode": _vm.checkModalCustomer === false ? 'edit' : 'add',
      "show-import-modal-detail": _vm.showImportModalDetail,
      "editContactCustomer": _vm.checkModalCustomer === false ? _vm.editContactCustomer : {},
      "customerId": _vm.ContactId,
      "readonlyModalDetail": _vm.readonly,
      "addContactID": _vm.contactsInfo.id
    },
    on: {
      "update:showImportModalDetail": function updateShowImportModalDetail($event) {
        _vm.showImportModalDetail = $event;
      },
      "update:show-import-modal-detail": function updateShowImportModalDetail($event) {
        _vm.showImportModalDetail = $event;
      },
      "close-modal-detail": _vm.handleCloseDetailContact,
      "refetch-data": _vm.handleEditData,
      "back": _vm.onBack,
      "ok-detail-contact": _vm.handleOkDetailContact
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }